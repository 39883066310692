﻿import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'arrayRangeFilter',
    pure: true
})
export class ArrayRangeDateFilterPipe implements PipeTransform {
    transform(value: any[], field: string, rango: Date[], inverso: boolean = false ): any[] {

        let newValue: any[] = [];
        if (rango  == [] || rango==null || rango.length < 2 || value==undefined || value==[] ) {
            return value;
        }

        let begin:Date = rango[0];
        let end:Date = rango[1];
        begin.setHours(0,0,0,0);

        for (let row of value) {
          let _data = (row[field] instanceof Date)? row[field] : new Date(row[field]);
            if (inverso) {
                if ((_data >= begin  && _data <= end) == false  ) { 
                    newValue.push(row);
                }
            } else {
                if ( _data >= begin  && _data <= end ) {
                    newValue.push(row);
                }
            }
        }
        return newValue;
    }
}
