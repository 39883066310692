import { Injectable } from '@angular/core';
import { HttpClient as Http, HttpResponse as Response } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AbstractService } from './abstract.service';
import { UsuarioModel } from '../models/usuario.model';
import { TokenModel } from '../models/token.model';
//import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments/environment';
import { PermissionManagment } from '../models/userPermisions.model';

@Injectable()
export class UserService extends AbstractService<any> {

  private redirectUrl: string = '/';
  private loginUrl: string = environment.LoginPath;// '/login';

  private modalInfo: string = 'modalInfo';

  constructor(http: Http, router: Router /*,modal:NgbModal*/) {
    super(http, router);
  }

  islogged(): boolean {
    let islog: boolean = false;
    let loggedUser = JSON.parse(localStorage.getItem(this.prelocal + 'loggedUser'));
    if (loggedUser != undefined && loggedUser != null) {
      islog = true;
    }
    return islog
  }

  GetLoggedUser(): Observable<UsuarioModel> {
    return this.Get("api/User/getLoggedUser", true)
  }

  getLoggedUserClient(): UsuarioModel {
    let loggedUser: UsuarioModel = JSON.parse(localStorage.getItem(this.prelocal + 'loggedUser'));
    return loggedUser;
  }

  setLoggedUserClient(loggedUser: UsuarioModel): void {
    localStorage.setItem(this.prelocal + 'loggedUser', JSON.stringify(loggedUser));
  }
  removeLoggedUserClient(): void {
    localStorage.removeItem(this.prelocal + 'loggedUser');
    localStorage.removeItem('')
  }

  getTokenClient(): TokenModel {
    let token: TokenModel = JSON.parse(localStorage.getItem(this.prelocal + 'token'));
    return token;
  }
  setTokenClient(token: TokenModel): void {
    localStorage.setItem(this.prelocal + 'token', JSON.stringify(token));
    localStorage.setItem(this.modalInfo, '0');
  }
  removeTokenClient(): void {
    localStorage.removeItem(this.prelocal + 'token');
    localStorage.removeItem(this.modalInfo);
  }

  getModalInfoStatus(): boolean {
    var info = localStorage.getItem(this.modalInfo);
    return info == '0';
  }

  setModalInfo(): void {
    localStorage.setItem(this.modalInfo, '1');
  }

  verifyHasPermission(CODE: string) : boolean {
      var user = this.getLoggedUserClient();
      return user.rolePermisions.some( p => p == CODE);
  }

  validSession(): Observable<boolean> {
    return this.Get("api/Usuario/validSession", true);
  }

  VerificarStatus(usuNick: string, nameSpace: string): Observable<string> {
    return this.Get(`api/Usuario/VerificarStatus/?usuNick=${usuNick}&nameSpace=${nameSpace}`, true);
  }

  getAllUsers(): Observable<UsuarioModel[]> {
    return this.Get("api/User", true);
  }


  getUsers(campo: string, condicion: string, filtro: string): Observable<UsuarioModel[]> {
    return this.Get(`api/User/getUsers/?campo=${campo}&condicion=${condicion}&filtro=${filtro}`, true);
  }

  getUsersRequests(campo: string, condicion: string, filtro: string): Observable<UsuarioModel[]> {
    return this.Get(`api/User/getUsersRequests/?campo=${campo}&condicion=${condicion}&filtro=${filtro}`, true);
  }

  getAllUsersRequests(): Observable<UsuarioModel[]> {
    return this.Get("api/User/getAllUsersRequests", true);
  }
  getAllUsersRequestsCount(): Observable<number> {
    return this.Get("api/User/getAllUsersRequestsCount", true);
  }

  getByUseId(UsuId: number): Observable<UsuarioModel> {
    return this.Get(`api/Usuario/${UsuId}`, true);
  }

  insert(user: UsuarioModel): Observable<boolean> {
    return this.Post(`api/User`, user, true);
  }
  update(user: UsuarioModel): Observable<boolean> {
    return this.Put(`api/User`, user, true);
  }
  aprovar(user: UsuarioModel): Observable<boolean> {
    return this.Post(`api/User/ApproveUserRequest`, user, true);
  }
  resetPasswordAdmin(UserId: number): Observable<boolean> {
    return this.Post(`api/User/AdminResetPassword/?UserId=${UserId}`, null, true);
  }

  getRedirectUrl(): string {
    return this.redirectUrl;
  }
  getLoginUrl(): string {
    return this.loginUrl;
  }

  setRedirectUrl(url: string): void {
    this.redirectUrl = url;
  }
  AceptarTerminos(usuId: number): Observable<boolean> {
    return this.Post(`api/User/AceptarTerminos/?usuId=${usuId}`, null, true);
  }

  GetUserAdminRole() : Observable<PermissionManagment> {
    return this.Get('api/User/getPermisionManagment', true);
  }
  
  SaveGenericUserRole(usuId: number, roleId: number) : Observable<boolean> {
    return this.Post(`api/User/setGenericUserRole/${usuId}/${roleId}`, null, true);
  }

  SavePersonalizedRole(usuId: number, customRoles: number[]) {
    return this.Post(`api/User/setPersonalizedRole/${usuId}`, customRoles, true);
  }

  RegisterAction(CODE:string) {
    return this.Post(`api/User/RecordAction/?CODE=${CODE}`, null, true);
  }


}
