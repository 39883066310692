﻿import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'arrayFilter',
    pure: true
})
export class ArrayFilterPipe implements PipeTransform {
    transform(value: any[], field: string, criterio: string, inverso: boolean = false, like:boolean = false): any[] {

        let newValue: any[] = [];
        if (criterio == "*" || criterio == "" || criterio==null) {
            return value;
        }
        for (let lista of value) {
            if (inverso) {
                if (lista[field] != criterio || (like== true && lista[field].toString().toUpperCase().indexOf(criterio.toUpperCase()) < 0)) {
                    newValue.push(lista);
                }
            } else {
                if (lista[field] == criterio || (like== true && lista[field].toString().toUpperCase().indexOf(criterio.toUpperCase()) > -1)) {
                    newValue.push(lista);
                }
            }
        }
        return newValue;
    }
}
