import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ClientService } from '../../services/client.service';
import { UsuarioModel } from '../../models/usuario.model';
import { Router } from '@angular/router';
import { ClienteModel } from '../../models/cliente.model';
import { ClienteSharedService } from '../../services/cliente-shared.service';
//import swal from 'sweetalert2';
declare var $: any;
@Component({
  selector: 'app-layout',
  templateUrl: 'layout.component.html',
  styleUrls: ['layout.component.css']
})
export class LayoutComponent implements OnInit, AfterViewInit {
  countRequests: number = 0;

  public user = new UsuarioModel();
  constructor(public csService: ClienteSharedService, private clientService: ClientService, private userService: UserService, private router: Router) {
    this.user = this.userService.getLoggedUserClient();

  }



  ngOnInit() {

  }
  ngAfterViewInit() {
    this.iniScroll();
    this.getCliente();
  }
  getCountRequest(): void {
  }
  getCliente(): void {
    this.clientService.getCliente(this.user.clienteNo).subscribe(data => {
      this.csService.cliente = data;
    }, error => {
      console.log(error);
    })
  }
  logOut(): void {
    //
    // swal.fire({
    //   title:'Salir',
    //   text:'¿Desea salir del sistema?',
    //   showCancelButton:true,
    //   cancelButtonText:'No',
    //   confirmButtonText:'Si',
    //   type:'question'
    // }).then(result=>{
    //     if(result.value == true){
    //       this.userService.removeLoggedUserClient();
    //       this.router.navigate(['/login']);
    //     }
    // });
  }

  iniScroll(): void {
    $(window).scroll(function () {
      if ($('#navbar')) {
        var navbarmenu = $('#navbar');
        if ($(this).scrollTop() > 120) {
          navbarmenu.removeClass('bg-opacity');
        } else {

          navbarmenu.addClass('bg-opacity');
        }
        if (navbarmenu[0] != undefined && navbarmenu[0].classList != undefined && navbarmenu[0].classList.contains('bg-opacity')) {

        }
      }
    });
  }
}
