import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoginRoutingModule } from '../modules/login/login-routing.module';

import {UserService} from './user.service';

@Injectable()
export class AuthorizeService  implements  CanActivate, CanActivateChild {
  logged: any ={clienteNo:''};
  constructor(private useService:UserService,private router:Router) {  this.logged = JSON.parse(localStorage.getItem("pc_loggedUser")); }

  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url: string = state.url;  
    if (this.useService.islogged()) {
      if(url != '/informacion-entrada' && url != '/terminos-y-condiciones'){
        this.validaAceptaTerminos();
      }
      return true;
    }

    if(url!='/logout')
    {
      this.useService.setRedirectUrl(url);
    }

  
    

    //this.router.navigate([ this.useService.getLoginUrl() ]);
    window.location.href=(this.useService.getLoginUrl()); 
    return false;

  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

   let url: string = state.url; 
    if(url=='/equipos-en-taller' && this.logged.clienteNo!='013395' ){
      this.router.navigate(['']);
      return false;
    }
    if(url.trim().indexOf('/')==0){
      url = '/'+ url.split('/')[1];
    }

    if (this.useService.islogged()) {
      if(url != '/informacion-entrada' && url != '/terminos-y-condiciones'){
        this.validaAceptaTerminos();
      }

      if(this.rutaValida(url)== false){
        this.router.navigate([ '/']);
        return false;
      }
      return true;
    }
    if(url!='/logout'){
      this.useService.setRedirectUrl(url);
    }
    

    this.router.navigate([ this.useService.getLoginUrl() ]);
    return false;
  }

  rutaValida(url:string):boolean{
    return true;
  }

  validaAceptaTerminos():void{
    let usu = this.useService.getLoggedUserClient();
    if(usu.aceptoTerm==false){
      this.router.navigate(["/informacion-entrada"]);
    }
  }

}
