import { Injectable } from '@angular/core';
import { HttpClient as Http, HttpResponse as Response } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { AbstractService } from './abstract.service';
import { CuentasPorPagarModel } from '../models/cuentaPorPagar.model';
import { PagoModel } from '../models/pago.model';
import { PagoDetalleModel } from '../models/pagoDetalle.model';
import { ClienteModel } from '../models/cliente.model';
import { BalancesModel } from '../models/balances.model';
import { SolicitudCambioModel } from '../models/solicitudCambio.model';
import { beginCardPayment } from '../models/beginCardPayment.model';
import { beginVoucherPayment } from '../models/beginVoucherPayment.model';
import { SafeValue } from '@angular/platform-browser';
import { savedCard } from '../models/savedCard.model';
import { PaymentRequestDTO } from '../models/cardPayment.model';
import { FacturaModel } from '../models/factura.model';
import { SolicitudComprobanteModel } from '../models/solicitudComprobante.model';
import { environment } from 'src/environments/environment';
import { ClientFileModule, ClientFileViewModel } from '../models/customer-files.model';

@Injectable()
export class ClientService extends AbstractService<any> {
  constructor(http: Http, router: Router) {
    super(http, router);
  }

  getCliente(clienteNo: string): Observable<ClienteModel> {
    return this.Get(`api/Cliente/${clienteNo}`, true)
    //return of({clienteNo:'00194',clienteNombre:'CEMENTOS CIBAO S A',email:'',rncCed:'R 102002134',telefono:'829-111-2214',consultor:'Manuel Jimenez',direccion1:'CARRETERA SANTIAGO BAITOA KM 8',direccion2:'',direccion3:'PALO AMARILLO',moneda:'DOP',limiteCredito:10000000.00,diascredito:30,fechaCR:  new Date("2019/03/28"),fechaUP:  new Date("2019/06/13")});
  }

  getAllCuentas(clienteNo: string): Observable<CuentasPorPagarModel[]> {
    return this.Get(`api/CuentaPorPagar/GetByClienteNo/?clienteNo=${clienteNo}`, true);
  }

  Pagar(pago: PagoModel): Observable<PagoModel[]> {
    return this.Post(`api/Pago/`, pago, true);
  }
  getPagosRecientes(clienteNo: string, top: number): Observable<PagoModel[]> {
    return this.Get(`api/Pago/GetPagosRecientes/?clienteNo=${clienteNo}&top=${top}`, true);
  }

  getHistorialPago(clienteNo: string): Observable<PagoModel[]> {
    return this.Get(`api/Pago/GetHistorialPago/?clienteNo=${clienteNo}`, true);
  }
  getHistorialPagoPaginator(clienteNo: string, pagina: number, rowsPorPage: number, beginDate: string, endDate: string): Observable<PagoModel[]> {
    return this.Get(`api/Pago/GetHistorialPagoPaginator/?clienteNo=${clienteNo}&pagina=${pagina}&rowsPorPage=${rowsPorPage}&beginDate=${beginDate}&endDate=${endDate}`, true);
  }

  getHistorialTransferencias(clienteNo: string): Observable<PagoModel[]> {
    return this.Get(`api/Pago/GetTransfPendiente/?clienteNo=${clienteNo}`, true);
  }

  getHistorialSolicitudComprobantePaginator(clienteNo: string, pagina: number, rowsPorPage: number, beginDate: string, endDate: string, moneda: string): Observable<SolicitudComprobanteModel[]> {
    return this.Get(`api/SolicitudComprobante/getHistory/${clienteNo}/${rowsPorPage}/${beginDate}/${endDate}/${moneda}/${pagina}`, true);
  }

  getHistorialFactura(clienteNo: string, pagina: number, rowsPerPage: number, beginDate: string, endDate: string, moneda: string): Observable<FacturaModel[]> {
    return this.Get(`api/Factura/getHistory/${clienteNo}/${rowsPerPage}/${beginDate}/${endDate}/${moneda}/${pagina}`, true);
  }

  getPagosDetalle(idPag: number): Observable<PagoDetalleModel[]> {
    return this.Get(`api/Pago/getDetalles?idPag=${idPag}`, true);
  }

  getBalances(clienteNo: string): Observable<BalancesModel> {
    return this.Get(`api/Cliente/getBalance?clienteNo=${clienteNo}`, true);
  }


  PagarFacturas(pago: PagoModel): Observable<string> {
    return this.Post(`api/Pago/PagarFacturas`, pago, true);
  }

  PagarFacturasCreditCard(pago: beginCardPayment): Observable<PaymentRequestDTO> {
    return this.Post(`api/Pago/PagarFacturasTarjeta`, pago, true);
  }

  PagarFacturasVoucher(pago: beginVoucherPayment): Observable<Boolean> {
    return this.Post(`api/Pago/PagarFacturasComprobante`, pago, true);
  }

  AplicarCredito(pago: PagoModel): Observable<number> {
    return this.Post(`api/Pago/AplicarCredito`, pago, true);
  }

  GetSavedCreditCards(usuId: number): Observable<savedCard[]> {
    return this.Get(`api/Pago/getSavedCards/${usuId}`, true);
  }

  AplyCardPayment(payment: PaymentRequestDTO): Observable<any> {
    return this.Post('https://testsecureacceptance.cybersource.com/silent/pay', payment, false);
  }

  RemoveCardToken(token: string): Observable<boolean> {
    return this.Post(`api/Pago/RemoveToken?token=${token}`, true);
  }

  GetLastCurrency(): Observable<number | null> {
    return this.Get('api/currencyRate/LastRate', true);
  }

  GetFileByFilter(page:number = 1, pageSize:number = 10, criteria:any): Observable<ClientFileViewModel> {
    return this.PostClick(`api/v1/clientFileModuleView/criteria?pageNo=${page}&pageSize=${pageSize}`,criteria, true, true)
  }

  GetModuls(): Observable<ClientFileModule[]> {
    return this.GetClick(`api/v1/configuration_group_items/configuration_groups/code/client_document_modules/`, true)
  }

}
