import { Component, OnInit } from '@angular/core';
import { ClienteModel } from 'src/app/models/cliente.model';
import { ClickCriteria, ClientFileModule, ClientFileViewModelFilters, Content } from 'src/app/models/customer-files.model';
import { ClientService } from 'src/app/services/client.service';
import { ClienteSharedService } from 'src/app/services/cliente-shared.service';
import { customerFilesColumns } from 'src/app/utils/columns-def';
import { environment } from 'src/environments/environment';

declare var $:any;

@Component({
  selector: 'app-customer-files',
  templateUrl: './customer-files.component.html',
  styleUrls: ['./customer-files.component.css']
})
export class CustomerFilesComponent implements OnInit {

  public filters:ClientFileViewModelFilters = new ClientFileViewModelFilters();
  public columnsCFiles = customerFilesColumns
  public fileContents:Content[];
  public contentInDisplay:Content = new Content();
  get cliente():ClienteModel{
    return this.csService.cliente;
  }
  public countPage:number = 1;
  public rowPerPage:number = 5;
  public indexPage:number=0;
  public criterias:ClickCriteria[] = [];
  public moduls:any[] = [];
  public rawModuls: ClientFileModule[] = [];

  constructor( private clientService: ClientService, public csService:ClienteSharedService) { }

  ngOnInit() {
      this.GetModuls()
      this.GetFiles();
  }

  ngAfterViewInit(){
    this.csService.change.subscribe(data=>{
      if(data==true){
        this.GetFiles();
      }
    });
  }

  GetFiles(page:number = 0) {
    this.prepareCriterias();
    this.clientService.GetFileByFilter(page, this.rowPerPage, this.criterias).subscribe(res => {
      this.countPage = Math.round(res.totalElements / this.rowPerPage)
      this.indexPage = page
      this.fileContents = res.content
    });
  }

  GetModuls() {
     this.clientService.GetModuls().subscribe(res => {
       this.rawModuls = res;
          res.forEach( modul => {
              this.moduls.push( { "text": this.ModuleNameToShow(modul.configurationItem.defaultValueString), "value": modul.configurationItem.id});
          })
     });
  }

  

  
  cleanFilters() {
    this.filters = new ClientFileViewModelFilters();
    this.filterChange();
  }

  cleanRNC(rncCed:string):string {
    var result = rncCed;
    if (rncCed) {
        result = rncCed.match('\[a-zA-Z]') ? rncCed.trim().replace(' ', '').substring(1) : rncCed;
    }
    return result;
  }

  prepareCriterias() {
    this.criterias = [];
    var cleanRNC = this.cleanRNC(this.cliente.rncCed);
    var baseCriteria: ClickCriteria = this.newCriteria("documentNumber", "and", "equal", cleanRNC);
    var baseVisibleCriteria: ClickCriteria = this.newCriteria("clientVisibility", "and", "like", "true");
    this.criterias.push(baseCriteria);
    this.criterias.push(baseVisibleCriteria);

    if(this.filters.createdAt) {
        var beginDate = this.getDbDateString(this.filters.createdAt[0]);
        var endDate = this.getDbDateString(this.filters.createdAt[1]);
        this.criterias.push(this.newCriteria("createdAt", "and", "between", beginDate, endDate));
    }

    if(this.filters.fileName != null && this.filters.fileName.length >= 2) {
      this.criterias.push(this.newCriteria("fileName", "and", "like", this.filters.fileName));
    }

    if(this.filters.comment != null && this.filters.comment.length >= 2) {
      this.criterias.push(this.newCriteria("comment", "and", "like", this.filters.comment));
    }

    if(this.filters.model != null && this.filters.model.length >= 2) {
      this.criterias.push(this.newCriteria("model", "and", "like", this.filters.model));
    }

    if(this.filters.serie != null && this.filters.serie.length >= 2) {
      this.criterias.push(this.newCriteria("serial", "and", "like", this.filters.serie));
    }

    if( this.filters.module != null && this.filters.module && 
        this.filters.module.toLowerCase() != 'todos' && 
        this.filters.module.toLowerCase() != 'all'
      ) {      
        this.criterias.push(this.newCriteria("module", "and", "equal", this.filters.module.toString()));      
    }

  }

  getDbDateString(date:Date){
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  filterChange(){
    this.GetFiles();
  }

  newCriteria(cField:string, cPredicate:string, cCondition:string, cValue:string, cValue2:string = "", cField2:string = "") : ClickCriteria {
    var cr = new ClickCriteria();
      cr.field = cField;
      cr.predicate = cPredicate;
      cr.condition = cCondition;
      cr.value = cValue;
      cr.value2 = cValue2;
      cr.field2 = cField2;
    return cr;
  }

  clickDownloadFile(item:Content):void {    
    const a = document.createElement("a");
    a.href = environment.amazonfilesUrl + item.fileUrl + "#portalFile";
    a.target = "blank";
    a.setAttribute("download", item.fileName);
    const b = document.createEvent("MouseEvents");
    b.initEvent("click", false, true);
    a.dispatchEvent(b);
  }

  rowClick(item:Content) {
    this.contentInDisplay = item;
    $('#modalDetail').modal();
  }

  downloadFile(fileUrl) {
    const win = window.open(
        environment.amazonfilesUrl + fileUrl,
        "_blank"
    );
    win.focus();
  }

  counter(no:number):any[]{
    return new Array(no);
  }

  ModuleNameToShow(defaultString:string):string {
    return (defaultString != '' && defaultString != null) ? defaultString :  'Todos'; 
  }

}
