export class ClienteModel{
  public clienteNo:string;
  public clienteNombre?:string;
  public email?:string;
  public rncCed:string;
  public telefono:string;
  public consultor:string;
  public direccion1?:string;
  public direccion2?:string;
  public direccion3?:string;
  public ciudad?:string;
  public moneda?:string;
  public limiteCredito?:number;
  public diascredito?:number;
  public fechaCR:Date;
  public fechaUP?:Date;
}
