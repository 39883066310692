export const  customerColumnsFilter  =  [
        { value: 'Name', text:'Nombre' , default:true },
        { value: 'RNC', text: 'RNC', width:170 , default:false },
        { value: 'CustomerNumber', text: 'Cliente no.',  default:false, condicion: { text: 'igual', value: "='{0}'"} },
        { value: 'Address1', text: 'Dirección', default:false },
        { value: 'Phone', text: 'Teléfono', default:false }
    ];

export const  userColumnsFilter  =  [
        { value: 'UserName', text:'Usuario' , default:true },
        { value: 'FirstName', text:'Nombres' , default:true },
        { value: 'LastName', text:'Apellidos' , default:true },
        { value: 'RNC', text: 'RNC', width:170 , default:false },
        { value: 'CustomerNumber', text: 'Cliente no.',  default:false, condicion: { text: 'igual', value: "='{0}'"} },
        { value: 'Email', text: 'Email', default:false }
    ];


export const  facturasVenciasColumns  =  [
  { value: 'documentoNo', text:'Factura', width:170  },
  { value: 'NCF', text:'NCF', width:250 },
  { value: 'docdate', text:'Fecha', width:130 , type:'date',format:'DD-MMM-YYYY' },
  { value: 'OrdCompra', text:'Orden de compra', width:220 },
  //{ value: 'montoItbis', text:'Itbis', type:'currency',format:'1.2-2', abs:true  },
  { value: 'balance', text:'Balance RD$', width:180, type:'currency',format:'1.2-2' },
  { value: 'balanceUsd', text:'Balance US$', width:180, type:'currency',format:'1.2-2' },
  { value: 'diasVencidos', text:'Días vencida', width:130  }
];

export const  notasCreditoColumns  =  [
  { value: 'documentoNo', text:'Factura', width:170  },
  { value: 'NCF', text:'NCF', width:250  },
  { value: 'docdate', text:'Fecha', width:130 , type:'date',format:'DD-MMM-YYYY' },
  { value: 'OrdCompra', text:'Orden de compra', width:220 },
  //{ value: 'montoItbis', text:'Itbis', type:'currency',format:'1.2-2'  },
  { value: 'balance', text:'Balance RD$', width:180, type:'currency',format:'1.2-2',abs:true  },
  { value: 'balanceUsd', text:'Balance US$', width:180, type:'currency',format:'1.2-2',abs:true  },
  { value: 'diasVencidos', text:'Días vencida', width:130  }
];


export const  facturasEstadoCuentaColumns  =  [
  { value: 'documentoNo', text:'Factura', width:160  },
  { value: 'NCF', text:'NCF', width:160  },
  //{ value: 'docdate', text:'Fecha', width:140 ,  type:'date', format:'DD-MMM-YYYY' },
  { value: 'OrdCompra', text:'Orden de compra', width:240  },
  //{ value: 'montoItbis', text:'Itbis', type:'currency',format:'1.2-2', abs:true  },
  { value: 'balance', text:'Balance RD$', width:160 , type:'currency',format:'1.2-2', },
  { value: 'balanceUsd', text:'Balance US$', width:160, type:'currency',format:'1.2-2' },
  { value: 'diasVencidos', text:'Días vencida', width:100  },
  { value: 'download', text:'', width:30, type:'icon'  },
  { value: 'select', text:'', width:80 , type:'checkbox',format:''}
];

export const  notaCreditoEstadoCuentaColumns  =  [
  { value: 'documentoNo', text:'Número', width:170 },
  { value: 'docdate', text:'Fecha', width:160, type:'date',format:'DD-MMM-YYYY' },
  { value: 'OrdCompra', text:'Referencia', width:220 },
  { value: 'balance', text:'Monto RD$', width:180, type:'currency',format:'1.2-2',abs:true },
  { value: 'balanceUsd', text:'Monto US$', width:150, type:'currency',format:'1.2-2',abs:true  },
  { value: 'select', text:'', width:80, type:'checkbox',format:''}
];
export const  notaCreditoProcesarPagoColumns  =  [
  { value: 'documentoNo', text:'Número',width:170 },
  { value: 'docdate', text:'Fecha', width:160, type:'date',format:'DD-MMM-YYYY' },
  { value: 'OrdCompra', text:'Referencia', width:220 },
  { value: 'balance', text:'Monto RD$', type:'currency',format:'1.2-2'  },
  { value: 'balanceUsd', text:'Monto US$', type:'currency',format:'1.2-2'  },
  { value: 'delete', text:'', width:80, type:'button',format:''}
];

export const  notaCreditoProcesarPagoSeleccionColumns  =  [
  { value: 'documentoNo', text:'Número', width:170 },
  { value: 'docdate', text:'Fecha', width:160, type:'date',format:'DD-MMM-YYYY' },
  { value: 'OrdCompra', text:'Referencia', width:220 },
  { value: 'balance', text:'Monto RD$', width:180, type:'currency',format:'1.2-2'  },
  { value: 'balanceUsd', text:'Monto US$', width:180, type:'currency',format:'1.2-2'  },
  { value: 'select', text:'', width:80, type:'checkbox',format:''}
];

export const  ultPagosColumns  =  [
  { value: 'idPag', text:'Número de transacción', width: 200 },
  { value: 'aprobacionNo', text:'Número de aprobación', width:250 },
  { value: 'reciboNo', text:'Recibo No', width:180},
  { value: 'tipo', text:'Tipo',width:100},
  { value: 'fechaCR', text:'Fecha', width:140, type:'date',format:'DD-MMM-YYYY'  },
  { value: 'moneda', text:'Moneda'  },
  { value: 'montoTotal', text:'Total pagado', width:200, type:'currency',format:'1.2-2' },
  { value: 'download', text:'', width:50, type:'icon'  }
];


export const  ultPagosDetailColumns  =  [
  { value: 'documentoNo', text:'Factura', width:170 },
  { value: 'NCF', text:'NCF', width:250  },
  { value: 'referencia1', text:'Orden de compra', width:220},
  { value: 'docdate', text:'Fecha factura', width:130, type:'date',format:'DD-MMM-YYYY'  },
  { value: 'montoPagado', text:'Monto pagado', width:220, type:'currency',format:'1.2-2' }
];


export const  pendingTransColumns  =  [
  { value: 'idPag', text:'Número de transacción', width: 200 },
  { value: 'aprobacionNo', text:'Número de aprobación', width:250 },
  { value: 'reciboNo', text:'Recibo No', width:180},  
  { value: 'fechaCR', text:'Fecha', width:140, type:'date',format:'DD-MMM-YYYY'  },
  { value: 'moneda', text:'Moneda'  },
  { value: 'montoTotal', text:'Total pagado', width:200, type:'currency',format:'1.2-2' },  
];


export const  ultFacturaColumns  =  [
  { value: 'NoFactura', text:'Número de factura',width:170 },
  { value: 'OrdenCompra', text:'Orden de compra',width:220 },
  { value: 'NCF', text:'NCF',width:250},
  { value: 'Monto', text:'Monto',width:180, type:'currency',format:'1.2-2'},
  { value: 'Fecha', text:'Fecha', width:160, type:'date',format:'DD-MMM-YYYY'  },
  { value: 'Moneda', text:'Moneda'  },
  { value: 'Total', text:'Total', width:220, type:'currency',format:'1.2-2' },
  { value: 'download', text:'', width:50, type:'icon'  }
];


export const  ultComprobanteColumns  =  [
  { value: 'Id', text:'Solicitud No',width:130 },
  { value: 'Type', text:'Tipo',width:170 },
  { value: 'Currency', text:'Moneda',width:90},
  { value: 'Amount', text:'Monto',width:180, type:'currency', format:'1.2-2'}, 
  { value: 'Date', text:'Fecha Solicitud', width:140,type:'date',format:'DD-MMM-YYYY' },
  { value: 'State', text:'Estatus', width:140, type: 'colored-state'  },
  { value: 'NoRecipe', text:'ReciboNo', width:140 },
  { value: 'AmountConfirmed', text:'Monto Aplicado', width:180, type:'currency',format:'1.2-2'}
];


export const  ultComprobanteDetailColumns  =  [
  { value: 'documentoNo', text:'Factura', width:170 },
  { value: 'NCF', text:'NCF', width:250},
  { value: 'referencia1', text:'Orden de compra', width:180},
  { value: 'docdate', text:'Fecha factura', width:130, type:'date',format:'DD-MMM-YYYY'},
  { value: 'montoPagado', text:'Monto pagado', width:140, type:'currency',format:'1.2-2'},
  { value: 'currency', text:'Moneda', width: 140}
];

export const  selectEditColumns  =  [
  //{ value: 'TXRGNO', text:'RNC / Cédula' }, no se va a incluir la actualizacion de cedula
  { value: 'PHONE_NUMBER1', text:'Teléfono' },
  { value: 'EMAIL', text:'Correo' },
  { value: 'CUADD2', text:'Direccion' },
  { value: 'CUADD3', text:'Sector' },
  //{ value: 'CUCYST', text:'Ciudad' }
];

export const  customerFilesColumns  =  [
  { value: 'fileName', text:'Nombre de Archivo', width:350 },
  { value: 'createdAt', text:'Fecha de Subida', type:'date',format:'DD-MMM-YYYY',width:150 },
  { value: 'module', text:'Modulo', width:175 },
  { value: 'model', text:'Modelo', width:125 },
  { value: 'serial', text:'Serie', width:130 },
  { value: 'comment', text:'Comentario ',  width:250  },
  { value: 'download', text:' ', width:50, type:'icon'  }
];
