import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'app-md-table',
  templateUrl: './md-table.component.html',
  styleUrls: ['./md-table.component.css']
})
export class MdTableComponent implements OnInit {
  public _data: any[]=[];

  @Input() columns: any[]=[];
  @Input() columnsDetail: any[]=[];
  @Output() dataChange = new EventEmitter<any>();
  @Output() clickDocNo = new EventEmitter<any>();
  @Output() onClickDownLoad = new EventEmitter<any>();
  @Output() onRowClick = new EventEmitter<any>();

  @Input() get data(): any[] { return this._data; };
  set data(val: any[]) {
      this._data = val;
      this.dataChange.emit(val);
  }

  @Output() onRowExpand = new EventEmitter<any>();

  onRowExpandEvent= (item: any) =>{
    this.onRowExpand.emit({
       item
    });
  };

  constructor() { }

  ngOnInit() {
  }

  onClickDocNo(item:any){
    this.clickDocNo.emit(item);
  }

  onClickDownLoadEvent(item:any):void{
    this.onClickDownLoad.emit(item);
  }

  onRowClickEvent(item:any):void{
    this.onRowClick.emit(item);
  }

}
