import { Pipe,PipeTransform} from '@angular/core';


@Pipe({
  name: 'sum',
  pure: false
})
export class SumPipe implements PipeTransform {
    transform(data: Array<any>, field: string, filter:string = '', value:string =''): any {
        if (data === undefined) {
            return 0;
        }
        let result = 0;

        let r =[];
        if(filter!==''){
          let filters = value.split(',');
          r= data.filter(f=>filters.indexOf(f[filter]) > -1);
        }
        let inData = [];
        if(r!=undefined && r!==null && r.length != undefined){
          inData = r;
        }else{
          inData = data;
        }


        for (let item of inData) {
          if (item[field] != undefined) {
            result= parseFloat(result.toString()) + parseFloat(item[field].toString());
          }
        }


        return result;
    }
}
