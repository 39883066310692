﻿import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'jsonDate'
})
export class JsonDatePipe implements PipeTransform {
    transform(value: any): any { 
        if (typeof (value) == "string" && value.toString().length > 0) {
            var intDate = parseInt(value.substr(6));
            if (intDate < 1) { return ""; }
            return new Date(intDate);
        } else {
            return value;
        }
    } 
}