import { Pipe,PipeTransform} from '@angular/core';


@Pipe({
  name: 'count',
  pure: false
})
export class CountPipe implements PipeTransform {
    transform(data: Array<any>, field: string, value:string): any {
      
        if (data === undefined || data.length < 1) {
            return 0;
        } 
        let result = 0;
        let filters = value.split(',');
        let r = data.filter(f=>filters.indexOf(f[field]) > -1);
        if(r!=undefined && r!==null && r.length != undefined){
          result = r.length;
        }
        return result;
    }
}
