import {
  HttpClient as  Http,
  HttpResponse  ,
  HttpHeaders as Headers, HttpErrorResponse } from '@angular/common/http';
import {Router} from '@angular/router';
import { Observable, throwError  } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';
//import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import {LoginPopupComponent} from '../components/login-popup/login-popup.component';
import {TokenModel} from '../models/token.model';
import {environment} from '../../environments/environment';

export abstract class AbstractService<T> {
  public host:string = environment.UrlBackEnd;
  public prelocal:string = environment.prelocal;

  constructor(protected _http:Http, protected router:Router /*, protected modal:NgbModal*/) { }

  getHost():string{
    return this.host;
  }


  openPopupLogin():void{
     //const modalRef = this.modal.open(LoginPopupComponent,{backdrop:'static',keyboard:false, size: 'sm'});
  }

  handleError(e: HttpErrorResponse ) {
    if (e.error instanceof ErrorEvent) {
      if(e.statusText == 'Unauthorized'){
        //this.router.navigate(['/login']);

        //this.openPopupLogin();
        window.location.href=(environment.LoginPath);

      }
       return throwError(e.error.message);
    }
    else{
      //if(e.statusText!=undefined && e.statusText == 'Unauthorized'){
      if(e.status==401){
        //this.openPopupLogin();
        //return throwError(`LOGIN`);
        //this.router.navigate(['/login']);
        window.location.href=(environment.LoginPath);
      }else{
        return throwError(/* `Backend returned code ${e.status}, ` +*/ `${(e.error.Message == null? e.message: e.error.Message) }`);

      }
    }
  }

  FindAll(actionUrl:string,use_token:boolean = false):Observable<T[]> {
    let headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
    if(use_token== true){
        let token:TokenModel = JSON.parse(localStorage.getItem(this.prelocal+'token'));
        headers.append('Token',token.Token);
    }
    let options = { headers:  headers  };
    return this._http.get<T[]>(this.host+actionUrl,  options).pipe(
      catchError(e => this.handleError(e))
    );
  }

  Find(id:number,actionUrl:string,use_token:boolean = false):Observable<T> {
    let headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
    if(use_token== true){
       let token:TokenModel  = JSON.parse(localStorage.getItem(this.prelocal+'token'));
        headers.append('token',token.Token);
    }
    let options =  { headers:  headers  };
    return this._http.get<T>(`${this.host+actionUrl}${id}`, options)
    .pipe(
      catchError(e => this.handleError(e))
    );
  }
  Get(actionUrl:string,use_token:boolean = false):Observable<T> {

    let headers = null;
    //headers.append("Access-Control-Allow-Origin", "*");
    if(use_token== true){
       let token:TokenModel =  JSON.parse(localStorage.getItem(this.prelocal+'token'));
        headers =   new Headers({ 'Content-Type': 'application/json; charset=utf-8', 'Token':token.Token });
    }else{
      headers =  new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
    }
    let options = { headers:  headers  } ;

    return this._http.get<T>(this.host+actionUrl,  options)
    .pipe(
      map((event: any) =>{

        return event;
      }),
      catchError(e => this.handleError(e))
    );
  }
  Post(actionUrl:string,params:any,use_token:boolean = false, getResponse:boolean = false):Observable<T> {

    let headers = new Headers({ 'Content-Type': 'application/json' });
    if(use_token== true){
        let token:TokenModel =  JSON.parse(localStorage.getItem(this.prelocal+'token'));
        headers =   new Headers({ 'Content-Type': 'application/json; charset=utf-8', 'Token':token.Token});
    }
    let options:any =  { headers:  headers } ;
    if(getResponse == true){
       options.observe = 'response' as 'body' ;
    }

    //{observe: 'response' as 'body'}
    return this._http.post<any>(this.host+actionUrl, params, options)
    .pipe(
      map((event: any) =>{
        return event;
      }),
      catchError(e => this.handleError(e))
    );
  }
  Put(actionUrl:string,params:any,use_token:boolean = false, getResponse:boolean = false):Observable<T> {

    let headers = new Headers({ 'Content-Type': 'application/json' });
    if(use_token== true){
      let token:TokenModel =  JSON.parse(localStorage.getItem(this.prelocal+'token'));
      headers =   new Headers({ 'Content-Type': 'application/json; charset=utf-8', 'Token':token.Token});
    }
    let options:any =  { headers:  headers } ;
    if(getResponse == true){
       options.observe = 'response' as 'body' ;
    }

    //{observe: 'response' as 'body'}
    return this._http.put<any>(this.host+actionUrl, params, options)
    .pipe(
      map((event: any) =>{
        return event;
      }),
      catchError(e => this.handleError(e))
    );
  }

  Delete(actionUrl:string, use_token:boolean = false):Observable<T> {
    let headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
    if(use_token== true){
       let token:TokenModel =  JSON.parse(localStorage.getItem(this.prelocal+'token'));
        headers.append('Token',token.Token);
    }
    let options =  { headers:  headers  };

    return this._http.delete<T>(this.host+actionUrl,   options)
    .pipe(
      catchError(e => this.handleError(e))
    );
  }

  PostClick(actionUrl:string,params:any,use_token:boolean = false, getResponse:boolean = false):Observable<T> {

    let headers = new Headers({ 'Content-Type': 'application/json' });
    if(use_token== true){
       
        headers =   new Headers({ 'Content-Type': 'application/json; charset=utf-8', 'Token': environment.token});
    }
    let options:any =  { headers:  headers } ;
    // if(getResponse == true){
    //    options.observe = 'response' as 'body' ;
    // }

    return this._http.post<T>(`${environment.WorkshopsUrl}/${actionUrl}`, params, options)
    .pipe(
      map((event: any) =>{
        return event;
      }),
      catchError(e => this.handleError(e))
    );
  }

  GetClick(actionUrl:string,use_token:boolean = false):Observable<T> {

    let headers = null;
    //headers.append("Access-Control-Allow-Origin", "*");
    if(use_token== true){
       
      headers =   new Headers({ 'Content-Type': 'application/json; charset=utf-8', 'Token': environment.token});
    }
    else{
      headers =  new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
    }
    let options = { headers:  headers  } ;

    return this._http.get<T>(`${environment.WorkshopsUrl}/${actionUrl}`,  options)
    .pipe(
      map((event: any) =>{

        return event;
      }),
      catchError(e => this.handleError(e))
    );
  }

}