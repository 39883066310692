import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-c-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.css']
})
export class CustomSelectComponent implements OnInit, OnChanges{
  public show = false;
  @Input() data: any[]=[];
  @Input() valueField: string='';
  @Input() textField: string='';
  @Input() value: any;
  @Input() disabled:boolean;
  @Input() emptyText:string='';
  @Input() width:number;
  public text: string='';


  @Output() valueChange = new EventEmitter();
  @Output() clicked = new EventEmitter();

  constructor() { }

  ngOnInit() {
    try{

      if(this.value==undefined || this.value==''){
        this.text = this.emptyText;
      }


      this.text = this.data.find(f=>f[this.valueField]==this.value)[this.textField];

    }
    catch(e){
      //debugger;
      //console.log(e);
    };
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes.value.previousValue) {
       if(this.value == null || this.value==this.emptyText || this.value==''){
         this.text=this.emptyText;
       }else{
         let item = this.data.find(f=>f[this.valueField]==this.value);
         if(item!=undefined && item!=null){
           this.text = item[this.textField];
         }
       }

    }
  }

  setClicked(){
    this.clicked.emit();
  }
  setValue(item:any){
    if(item==null || item==undefined){
      this.value = null;
      this.valueChange.emit(this.value);
      this.show = (!this.show);
      this.text=this.emptyText;
    }else{
      this.value = item[this.valueField];
      this.valueChange.emit(this.value);
      this.text = item[this.textField];
      this.show = (!this.show);
    }

  }

  leave():void{
   this.show = false;
  }

}
