import { Component, OnInit } from '@angular/core';
// import {UserService} from '../../services/user.service';
// import  {Swal} from  '../../utils/sweetalert';
// import {UsuarioModel} from '../../models/usuario.model';
// import {Router} from '@angular/router';

@Component({
  selector: 'app-politicas',
  templateUrl: './politicas.component.html',
  styleUrls: ['./politicas.component.css']
})
export class PoliticasComponent implements OnInit {
  // public acepto:boolean =false;
  // public usuario =  new UsuarioModel();
constructor(){}
  // constructor(private userService:UserService, private router:Router) {
  //   this.usuario = this.userService.getLoggedUserClient();
  // }
  // 
  ngOnInit() {
  }
  //
  //
  // aceptar():void{
  //   Swal.show.fire({
  //     text:'Enviando...',
  //     showCancelButton:false,
  //     showConfirmButton:false,
  //     showCloseButton:false,
  //     allowEnterKey:false,
  //     allowOutsideClick:false,
  //     allowEscapeKey:false
  //   });
  //   Swal.show.showLoading();
  //   this.userService.AceptarTerminos(this.usuario.usuId).subscribe(data=>{
  //     Swal.show.hideLoading();
  //     Swal.show.clickConfirm();
  //     if(data==true){
  //       this.usuario.aceptoTerm = data;
  //       this.userService.setLoggedUserClient(this.usuario);
  //       this.router.navigate(['/']);
  //     }
  //   },error=>{
  //     Swal.show.hideLoading();
  //     Swal.show.clickConfirm();
  //     alert(error);
  //   });
  // }

}
