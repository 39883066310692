import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { LayoutComponent } from './components/layout/layout.component';
import { HomeComponent } from './components/home/home.component';
import { AuthorizeService } from './services/authorize.service';
import { UserService } from './services/user.service';
import { HeaderComponent } from './components/layout/header/header.component';
import { ContactComponent } from './components/layout/contact/contact.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { FloatChatComponent } from './components/layout/float-chat/float-chat.component';
import { PoliticasComponent } from './components/politicas/politicas.component';
import { HistorialFacturasComponent } from './components/historial-factura/historial-factura.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClientService } from './services/client.service';
import { ClienteSharedService } from './services/cliente-shared.service';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
defineLocale('es', esLocale);


import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TerminosComponent } from './components/terminos/terminos.component';
import { FirstloginMesageComponent } from './components/firstlogin-mesage/firstlogin-mesage.component';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { NgArrayPipesModule } from 'ngx-pipes';
import { NgPipesModule } from 'ngx-pipes';
import { CustomerFilesModule } from './modules/customer-files/customerFiles.module';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HomeComponent,
    HeaderComponent,
    ContactComponent,
    FooterComponent,
    FloatChatComponent,
    PoliticasComponent,
    TerminosComponent,
    FirstloginMesageComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgArrayPipesModule,
    NgPipesModule,
    CustomerFilesModule
  ],
  exports: [HomeComponent],
  providers: [AuthorizeService, UserService, HttpClient, ClientService, ClienteSharedService,
    AuthInterceptor, {
      provide: HTTP_INTERCEPTORS,
      useExisting: AuthInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
