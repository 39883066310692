
export class UsuarioModel{
  public usuId:number;
  public usuNick:string;
  public usuPass:string;
  public usuEmail?:string;
  public ReqCambioPass:boolean;
  public clienteNo?:string;
  public usuActivo:boolean;
  public fechaCR:Date;
  public fechaUP?:Date;
  public aceptoTerm:boolean;
  public aceptoPP:boolean;
  
  public roleDescription: string;
  public rolePermisions: string[];
}
