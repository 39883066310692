import { Component, OnInit, AfterViewInit } from '@angular/core';
import {ClienteSharedService} from '../../../services/cliente-shared.service';
import {UserService} from '../../../services/user.service';
import {Router} from '@angular/router';
import  {Swal} from  '../../../utils/sweetalert';
import { environment } from 'src/environments/environment';
import * as jQuery from 'jquery';


declare var $:any;



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  metrickey: string = "";
  logged: any ={clienteNo:''};

  constructor(private router:Router, private usuService:UserService, public chService:ClienteSharedService) {
       this.router.routeReuseStrategy.shouldReuseRoute = () => false;
       this.metrickey = this.cybs_dfprofiler(environment.production);
       this.logged = JSON.parse(localStorage.getItem("pc_loggedUser"));
   }   

  ngOnInit() {
    //console.log(this.logged.clienteNo);
  }
  ngAfterViewInit(){

  }

  usePermision(code:string): boolean {
    return this.usuService.verifyHasPermission(code);
   }
  openMenu():void{
    if (document.getElementById('responsive-navbar').classList.contains('active')) {
        document.getElementById('responsive-navbar').classList.remove('active');
    } else {
        document.getElementById('responsive-navbar').classList.add('active');
    }
  }
  
  logOut():void{
    Swal.question({
      text:'¿Desea cerrar sesión?'
    }).then(result=>{
      if(result.value){
        this.usuService.removeTokenClient();
        this.usuService.removeLoggedUserClient();
        //this.router.navigate(['/login']);
        window.location.href=(environment.LoginPath);
      }
    });
  }

  cybs_dfprofiler(isProd:boolean)
  {  
    var fp = $("#fpdeviceId").attr("value");   
      if(!fp) {
        var merchantID = environment.merchantID;
        if (isProd) {
          var org_id = 'k8vif92e';
        } else {
            var org_id = '1snn5n9w';
        }
        
        var sessionID = new Date().getTime();    
        var metricSource = `https://h.online-metrix.net/fp/tags.js?org_id=${org_id}&session_id=${merchantID}${sessionID}`       

        var tmscript = document.createElement("script");
        tmscript.src = metricSource ;
        tmscript.type = "text/javascript";	
        document.head.appendChild(tmscript);

        var input = document.createElement("input");
        input.type = "hidden";
        input.id = "fpdeviceId";
        input.name = "fpdeviceId";
        input.value = `${sessionID}`;

        document.body.appendChild(input);

        var noscript = document.createElement("noscript");        

        var iframe = document.createElement("iframe")
        iframe.setAttribute('style',"width: 100px; height: 100px; border: 0; position:absolute; top: -5000px;");
        iframe.src = metricSource;

        noscript.appendChild(iframe)

        document.body.prepend(noscript);                  
        return  `${sessionID}`;
    }
  }
}
