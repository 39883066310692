import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MasterdetailtableModule} from '../masterdetailtable/masterdetailtable.module';
import { CustomerFilesRoutingModule } from './customerFiles-routing.module';
import { CustomerFilesComponent } from '../../components/customer-files/customer-files.component';
import { ClientService } from '../../services/client.service';
import { PipesModule } from '../pipes/pipes.module';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { FormsModule, ReactiveFormsModule   } from "@angular/forms";
import {CustomselectModule} from '../customselect/customselect.module';

@NgModule({
  declarations: [CustomerFilesComponent],
  imports: [
    BsDatepickerModule.forRoot(),
    CommonModule,
    CustomerFilesRoutingModule,
    MasterdetailtableModule,
    PipesModule,
    FormsModule, ReactiveFormsModule,
    CustomselectModule 
  ],
  providers:[ClientService, BsLocaleService]
})
export class CustomerFilesModule { }
