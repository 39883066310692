    
    export interface CreatedByUser {
        id: number;
        username: string;
        ldap: boolean;
        lastAccessDate: Date;
        status: string;
        createdAt: Date;
        updatedAt: Date;
        loginAttempts: number;
        role?: any;
        userApplications?: any;
        imcaUser: ImcaUser;
        externalUser?: any;
        userHardware?: any;
    }

    export interface ImcaUser {
        id: number;
        employeeCode: string;
        firstName: string;
        lastName: string;
        identification: string;
        address: string;
        birthdate: string;
        gender: string;
        image: string;
        supervisorCode: string;
        phoneNumber: string;
        cellphoneNumber: string;
        contactCode: string;
        fleet: string;
        extension: string;
        stationNumber: string;
        stationTitle: string;
        email: string;
        user?: any;
        authorizerUserId?: any;
        dateEntry: string;
        position?: any;
        createdAt: Date;
        updatedAt: Date;
        status: boolean;
    }

    export interface UpdatedByUser {
        id: number;
        username: string;
        ldap: boolean;
        lastAccessDate: Date;
        status: string;
        createdAt: Date;
        updatedAt: Date;
        loginAttempts: number;
        role?: any;
        userApplications?: any;
        imcaUser: ImcaUser;
        externalUser?: any;
        userHardware?: any;
    }

    export class Content {
        module: string;
        moduleId: number;
        fileName: string;
        fileUrl: string;
        clientVisibility: string;
        clientId: number;
        cuno: string;
        model: string;
        serial: string;
        comment: string;
        status: boolean;
        createdByUser: CreatedByUser;
        updatedByUser: UpdatedByUser;
        createdAt: Date;
        updatedAt: Date;
    }

    export interface Sort {
        sorted: boolean;
        unsorted: boolean;
    }

    export interface Pageable {
        sort: Sort;
        pageSize: number;
        pageNumber: number;
        offset: number;
        unpaged: boolean;
        paged: boolean;
    }

    export interface ClientFileViewModel {
        content: Content[];
        pageNo: number;
        pageSize: number;
        totalElements: number;
        first: boolean;
        numberOfElements: number;
        totalPages: number;
        hasContent: boolean;
        last: boolean;
        pageable: Pageable;
    }

    export class ClientFileViewModelFilters {
        createdAt: Date[];
        fileName: string;
        module: string;
        model: string;
        serie: string;
        comment: string;
    }

    export class ClickCriteria {
        field: string;
        predicate: string;
        condition: string;
        value: string;
        value2: string;
        field2: string;
    }

    export interface ConfigurationGroup {
        id: number;
        status: boolean;
        code: string;
        name: string;
        translate: string;
        createdByUser?: any;
        updatedByUser?: any;
        createdAt: Date;
        updatedAt?: any;
    }

    export interface ConfigurationItem {
        id: number;
        status: boolean;
        code: string;
        name: string;
        translate: string;
        defaultValueString: string;
        defaultValueString2?: any;
        defaultValueFloat: number;
        defaultValueFloat2?: any;
        defaultValueFloat3?: any;
        defaultValueFloat4?: any;
        defaultValueFloat5?: any;
        defaultValueFloat6?: any;
        order?: any;
        createdByUser?: any;
        updatedByUser?: any;
        createdAt: Date;
        updatedAt: Date;
    }

    export interface ClientFileModule {
        id: number;
        status: boolean;
        code: string;
        colorSelect?: any;
        configurationGroup: ConfigurationGroup;
        configurationItem: ConfigurationItem;
        createdByUser?: any;
        updatedByUser?: any;
        createdAt: Date;
        updatedAt: Date;
    }
