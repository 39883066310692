import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PipesModule} from '../../modules/pipes/pipes.module';
import { MasterdetailtableRoutingModule } from './masterdetailtable-routing.module';
import { MdTableComponent } from '../../components/md-table/md-table.component';

@NgModule({
  declarations: [MdTableComponent],
  imports: [
    CommonModule,
    MasterdetailtableRoutingModule,
    PipesModule
  ],
  exports:[MdTableComponent]
})
export class MasterdetailtableModule { }
