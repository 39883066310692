// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  prelocal: 'pc_',
  cybersourceUrl: 'https://secureacceptance.cybersource.com/silent/pay',
  UrlBackEnd: "https://portalclientesimcaapi.azurewebsites.net/",
  AppId: "A9A5EF4F-421E-40B8-98F3-DC03E9B7001D",
  FilesPath: "https://portalclientesimcaapi.azurewebsites.net/files/",
  BankUrl: "https://botondepago.bpd.com.do/bdp/index.html",
  LoginPath: "https://imcaenlinea.com/",
  merchantID: 'visanetdr_000000423824002',
  WorkshopsUrl: 'https://imcaclick.com:8444',
  token: "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiaWF0IjoxNjM5NTEyNjgzLCJleHAiOjE3MzQyMDcwODN9.55GM3PqKOV67DuMMuN_2adffQMRxKCskMI8higa6y3A",
  botonPPUrl: "https://botondepago.bpd.com.do/bdp/index.html",
  amazonfilesUrl: "https://crmclick.s3.amazonaws.com/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.

 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
