import { Pipe, PipeTransform } from '@angular/core';
import {format as _DateFormat} from 'date-fns';

@Pipe({
  name: 'fecha'
})
export class fechaPipe implements PipeTransform {
  transform(value: Date, format: string ='YYYY-MM-DD hh:mm A', locale:string='es'): string {
   
    if(value== null || value == undefined){
      return '';
    }

    return   _DateFormat(value,format,{locale:locale});
  }
}
