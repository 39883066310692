import { NgModule } from '@angular/core';
import {fechaPipe} from '../../pipes/fecha.pipe';
import {ArrayFilterPipe} from '../../pipes/arrayFilter.pipe';
import {JsonDatePipe} from '../../pipes/json-date.pipe';
import {GroupByPipe} from '../../pipes/group-by.pipe';
import {SumPipe} from '../../pipes/sum.pipe';
import {CountPipe} from '../../pipes/count.pipe';
import {ArrayRangeDateFilterPipe} from '../../pipes/arrayRangeDateFilter.pipe';

@NgModule({
  declarations: [fechaPipe, ArrayFilterPipe,JsonDatePipe, GroupByPipe, SumPipe, CountPipe,
  ArrayRangeDateFilterPipe],
  exports: [
    fechaPipe, ArrayFilterPipe,JsonDatePipe,GroupByPipe, SumPipe, CountPipe,
    ArrayRangeDateFilterPipe
  ]
})
export class PipesModule { }
