import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomerFilesComponent } from '../../components/customer-files/customer-files.component';
const routes: Routes = [
  {path:'',component:CustomerFilesComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerFilesRoutingModule { }
