
import SWAL from 'sweetalert2';
  export class Swal {
    static show = SWAL;
    static  success(config:any):any{
        return SWAL.fire({
          animation: false,
          customClass: {
            icon: 'swal2-arabic-question-mark',
            popup: 'alert-container-popup',
          },
          html:`
          <div class="modal-dialog">
            <div class="modal-content">
                <div class="content-custom-modal">
                    <div class="icon-modal"><img src="assets/template/img/icons/check.png" alt=""></div>
                    <div class="title-modal">${config.title}</div>
                    <div class="text-modal">${config.text}</div>
                    <a class="btn-close-modal pointer" id="ok" data-dismiss="modal"><img src="assets/template/img/icons/arrow-left.png" alt="">Regresar</a>
                </div>
            </div>
          </div>
           `,
           showConfirmButton:false,
           //backdrop:true,
           allowOutsideClick:false,
          //type:'success',
          onBeforeOpen: () =>{
            const content = SWAL.getContent();
            const $ = content.querySelector.bind(content);
            const ok = $('#ok');
            //swal.showLoading();
            ok.addEventListener('click', () => {
              //swal.close();
              SWAL.clickConfirm();
            })
          }
        })
      }
    static  question(config:any):any{
        return SWAL.fire({
          animation: false,
          customClass: {
            icon: 'swal2-arabic-question-mark',
            popup: 'alert-container-popup',
          },
          html:`
          <!-- Modal content-->
              <div class="modal-content">
                  <div class="content-modal-delete">

                      <div class="title-modal">${config.text}</div>
                      <div class="content-confirm-buttons">
                          <div class="button-main">
                              <button data-dismiss="modal" type="button" id="ok" class="btn-main">${config.confirmTextButton==undefined? 'Si':config.confirmTextButton }</button>
                          </div>
                          <div class="button-main-white">
                              <button data-dismiss="modal" type="button" id="cancel" class="btn-main">${config.cancelTextButton==undefined? 'No':config.cancelTextButton}</button>
                          </div>

                      </div>
                  </div>
              </div>
           `,
           showConfirmButton:false,
           //backdrop:true,
           allowOutsideClick:false,
          //type:'success',
          onBeforeOpen: () =>{
            const content = SWAL.getContent();
            const $ = content.querySelector.bind(content);
            const ok = $('#ok');
            const cancel = $('#cancel');
            //swal.showLoading();
            ok.addEventListener('click', () => {
              //swal.close();
              SWAL.clickConfirm();
            });
            if(cancel!=undefined){
              cancel.addEventListener('click', () => {
                SWAL.clickCancel();
              });
            }
          }
        })
      }
    static  warning(config:any):any{
          return SWAL.fire({
            animation: false,
            customClass: {
              icon: 'swal2-arabic-warning-mark',
              popup: 'alert-container-popup',
            },
            html:`
            <div class="modal-dialog">
              <div class="modal-content">
                  <div class="content-custom-modal">
                      <div class="icon-modal"><img src="assets/template/img/icons/warning.png" alt=""></div>
                      <div class="title-modal">${config.title}</div>
                      <div class="text-modal">${config.text}</div>
                      <a class="btn-close-modal pointer" id="ok" data-dismiss="modal"><img src="assets/template/img/icons/arrow-left.png" alt="">ok</a>
                  </div>
              </div>
            </div>
             `,
             showConfirmButton:false,
             //backdrop:true,
             allowOutsideClick:false,
            //type:'success',
            onBeforeOpen: () =>{
              const content = SWAL.getContent();
              const $ = content.querySelector.bind(content);
              const ok = $('#ok');
              //swal.showLoading();
              ok.addEventListener('click', () => {
                //SWAL.close();
                SWAL.clickConfirm();
                
              })
            }
          })
        }
    static acceptTandC():any{
      var IsTCOK = false;
      return SWAL.fire({
        animation: false,
        customClass: {
          //icon: 'swal2-arabic-question-mark',
          popup: 'alert-container-popup',
        },
        html:`
        <div class="modal-dialog">
          <div class="modal-content">
              <div class="content-custom-modal">
                  <div class="icon-modal"><img src="assets/template/img/icons/info.png" alt=""></div>
                  <div class="title-modal">¿Desea realizar el pago de estas facturas?</div>
                  <div class="text-modal">
                    <div id="chx-mas" class="chx-mas unchk"></div>
                    <span class="tandcspn">Acepto las <a id="tcbtn" target="blank" href="#/politicas-de-privacidad">politicas de privacidad</a>
                    y los <a id="trmc" target="blank" href="#/terminos-y-condiciones">terminos y condiciones</a>
                    </span>
                  </div>
                      <div class="content-confirm-buttons">
                          <div class="button-main">                            
                            <a class="btn-close-modal pointer" id="cancel" data-dismiss="modal"><i class="fa fa-times" aria-hidden="true"></i> Cancelar</a>
                          </div>
                          <div class="button-main-white">
                          <a class="btn-close-modal pointer hide" id="ok" data-dismiss="modal"><i class="fa fa-arrow-right" aria-hidden="true"></i> Continuar</a> 
                          </div>
                      </div>
              </div>
          </div>
        </div>
         `,
         showConfirmButton:true,
         //backdrop:true,
         confirmButtonClass:'btnAceptPolitica',
         confirmButtonText:'<i class="fa fa-arrow-right" aria-hidden="true"></i> Continuar',
         allowOutsideClick:false,
         onOpen: function (){
          SWAL.disableConfirmButton();
         },
   
        onBeforeOpen: () =>{
          const content = SWAL.getContent();
          const $ = content.querySelector.bind(content);
          const ok = $('#ok');
          const cancel = $('#cancel');
          const tcbtn = $('#tcbtn');
          const trmc =$('#trmc');
         
          cancel.addEventListener('click', () => {
            //swal.close();
            SWAL.clickCancel();   
          });
          tcbtn.addEventListener('click', () => {           
            SWAL.clickCancel();
          });

          trmc.addEventListener('click', () => {           
            SWAL.clickCancel();
          });
 


          // ok.addEventListener('click', () => {
          //   //swal.close();
          //   if(IsTCOK){
          //     SWAL.clickConfirm();  
          //   }          
          //   else {
          //     this.warning({title: 'TERMINOS Y CONDICIONES', text: 'Debe aceptar las politicas de privacidad y los terminos y condiciones para proceder.'}).then( result => {
          //       this.acceptTandC(); 
          //     })
          //   }            
          // });


          $(".chx-mas").addEventListener( 'click', () => {
           
              if($(".chx-mas").classList.contains('unchk')) { 
                document.getElementById("chx-mas").classList.add("chked");            
                document.getElementById("chx-mas").classList.remove("unchk");
                IsTCOK = true;
                SWAL.enableConfirmButton();
              }
              else {
                document.getElementById("chx-mas").classList.remove("chked");            
                document.getElementById("chx-mas").classList.add("unchk"); 
                IsTCOK = false;  
                SWAL.disableConfirmButton() ; 
                //ok.removeEventListener("click", null, false);    
                
              }       
             })
        }
      })
    }

    static  wait(message:string):any{
      return SWAL.fire({ type: 'info', text: message, showConfirmButton: false, showCancelButton: false, showCloseButton: false, allowEscapeKey: false, allowOutsideClick: false})      
    }

  }
