import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-float-chat',
  templateUrl: './float-chat.component.html',
  styleUrls: ['./float-chat.component.css']
})
export class FloatChatComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  openChat():void{
    window.open("http://chat.imcadom.com/Code/webchatLogin.php","chatPopop","left=200,top=100,fullscreen=no");
  }

}
