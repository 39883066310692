import { Injectable, EventEmitter } from '@angular/core';
import { ClienteModel } from '../models/cliente.model';
@Injectable()
export class ClienteSharedService {
  private _cliente = new ClienteModel();
  public change = new EventEmitter<boolean>();
  public changed = false;

  constructor() { }

  get cliente() {
    return JSON.parse(JSON.stringify(this._cliente));
  }
  set cliente(cli: ClienteModel) {
    this._cliente = cli;
    if (this.changed == false) {
      this.change.emit(true);
      this.changed = true;
    }
  }

  modalUSDShowed(): boolean {
    return JSON.parse(localStorage.getItem('modalUSDShowed'));
  };
  setModalUSDShowed(): void {
    localStorage.setItem('modalUSDShowed', 'true');
  };

  unsetModalUSDShowed(): void {
    localStorage.setItem('modalUSDShowed', 'false');
  };
}
